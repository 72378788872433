











import Component from 'vue-class-component';

import UiPageHeader from '@/components/ui/UiPageHeader.vue';

import { BaseVue } from '../../BaseVue';
import WrappingTreatment from '../../components/org/Taxes.vue';

@Component({
  components: {
    WrappingTreatment,
    UiPageHeader,
  },
})
export default class WrappingTreatmentClass extends BaseVue {
  public selectedTab = 0;
}
